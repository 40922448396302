import { createTheme } from "@mui/material"

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    contrast: true
  }
}

const lightPrimaryColor = "#F1F7FB"
const primaryColor = "#4a9ad5"
const lightSecondaryColor = "#e0e0e0"
const secondaryColor = "#9e9e9e"
const darkSecondaryColor = "#616161"
const white = "#FFFFFF"

const AppTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: white,
    },
    secondary: {
      main: secondaryColor,
      light: lightSecondaryColor,
      dark: darkSecondaryColor,
    },
    background: {
      default: "#FAFAFA",
      paper: white,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contrast" },
          style: {
            color: white,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: white,
            [`&:hover`]: {
              backgroundColor: "rgb(255, 255, 255, .4)",
              border: "1px solid white",
            },
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          width: "150px",
          "&.Mui-selected": {
            backgroundColor: lightPrimaryColor,
            color: primaryColor,
          },
          "&:hover": {
            backgroundColor: lightPrimaryColor,
            color: primaryColor,
          },
        },
      },
    },
  },
})

export default AppTheme
