import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import {
  Alert,
  AppBar,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material"
import {
  createTheme,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles"
import { jsPDF } from "jspdf"

import PaperArialRegular from "fonts/PaperArialRegular"
import PaperArialBold from "fonts/PaperArialBold"
import DocumentReportView from "components/views/documents/DocumentReportView"
import LoadingModal from "components/views/generic/LoadingModal"
import DocumentReportSignDialog from "components/views/documents/report/DocumentReportSignDialog"
import { TaskAlt } from "@mui/icons-material"
import useDocumentShared from "model/documents/reports/useDocumentShared"
import AppTheme from "components/AppTheme"

const StyledHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: "90%",
  maxWidth: "816px",
  margin: theme.spacing(4),
}))

const StyledAlert = styled(Alert)({
  justifyContent: "center",
  width: "100%",
})

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}))

const MobileReportTheme = createTheme({
  ...AppTheme,
  typography: {
    fontSize: 30,
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "20px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          paddingTop: "20px",
          paddingBottom: "20px",
        },
      },
    },
  },
})

const PdfSharedPage = ({ teamKey }: { teamKey: string }) => {
  const { sharedId } = useParams()
  const localGeneration = useLocalGeneration()
  const disableOpenTracking = useDisableOpenTracking()
  const [viewData, input] = useDocumentShared({
    teamKey,
    sharedId,
    disableOpenTracking,
  })
  const reportData = viewData?.report || null
  const documentReportRef = useRef<HTMLDivElement>(null)
  const [isCreatingPdf, setIsCreatingPdf] = useState(false)
  const [isReportLoaded, setIsReportLoaded] = useState(false)
  const pdfFilename = reportData?.filename || `${sharedId}.pdf`
  const pdfDocumentUrl = `/api/pdf/${teamKey}/shared/${sharedId}?filename=${encodeURIComponent(
    pdfFilename
  )}`
  const onDownloadPdf = useCallback(() => {
    if (!localGeneration) {
      window.location.href = pdfDocumentUrl
    } else if (documentReportRef.current) {
      setIsCreatingPdf(true)
      createPdfFromHtml(documentReportRef.current, pdfFilename, () => {
        setIsCreatingPdf(false)
      })
    }
  }, [localGeneration, pdfDocumentUrl, pdfFilename])
  const onReportLoaded = useCallback(
    () => setIsReportLoaded(true),
    [setIsReportLoaded]
  )
  useEffect(() => {
    const viewport = document.getElementById("viewport") as HTMLMetaElement
    const originalContent = viewport.getAttribute("content") || ""
    viewport.setAttribute("content", "width=816")
    return () => {
      viewport.setAttribute("content", originalContent)
    }
  }, [])
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  if (!sharedId) return <></>
  return (
    <ThemeProvider theme={isMobileScreen ? MobileReportTheme : AppTheme}>
      <div>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{
            backgroundColor: theme.palette.background.default,
            displayPrint: "none",
          }}
          style={{
            transition: "opacity 0.5s",
            opacity: isReportLoaded ? 1 : 0,
          }}
        >
          {viewData?.signButtonTitle && (
            <StyledHeaderContainer>
              <Button
                size={"large"}
                variant="contained"
                color="success"
                disabled={!isReportLoaded}
                onClick={() => {
                  input?.signDocument()
                }}
              >
                {viewData?.signButtonTitle}
              </Button>
            </StyledHeaderContainer>
          )}
          {viewData?.viewAndPrintPdfButtonTitle && (
            <StyledHeaderContainer>
              <Button
                size={"large"}
                variant="contained"
                color="primary"
                disabled={!isReportLoaded}
                onClick={onDownloadPdf}
                sx={{ marginBottom: 1 }}
              >
                {viewData.viewAndPrintPdfButtonTitle}
              </Button>
              {viewData.signingCompletedAlertText && (
                <StyledAlert icon={false} severity="success">
                  <StyledBox>
                    <TaskAlt />
                    <Typography>
                      {viewData.signingCompletedAlertText}
                    </Typography>
                  </StyledBox>
                </StyledAlert>
              )}
            </StyledHeaderContainer>
          )}
        </AppBar>
        <div ref={documentReportRef}>
          <DocumentReportView
            onReportLoaded={onReportLoaded}
            reportData={reportData}
          />
        </div>
        <DocumentReportSignDialog viewData={viewData} input={input} />
        <LoadingModal open={isCreatingPdf} />
      </div>
    </ThemeProvider>
  )
}

const useDisableOpenTracking = (): boolean => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get("disableOpenTracking") === "true"
  }, [search])
}

const useLocalGeneration = (): boolean => {
  const { search } = useLocation()
  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return searchParams.get("local") === "true"
  }, [search])
}

const createPdfFromHtml = (
  htmlElement: HTMLDivElement,
  filename: string,
  completion: () => void
) => {
  const doc = new jsPDF({
    orientation: "portrait",
    format: "letter",
    unit: "px",
    hotfixes: ["px_scaling"],
    putOnlyUsedFonts: true,
    compress: true,
  })
  doc.addFileToVFS("PaperArialRegular.ttf", PaperArialRegular.base64)
  doc.addFont("PaperArialRegular.ttf", "PaperArial", "normal")
  doc.addFileToVFS("PaperArialBold.ttf", PaperArialBold.base64)
  doc.addFont("PaperArialBold.ttf", "PaperArial", "bold")
  doc.setFont("PaperArial")
  doc.html(htmlElement, {
    margin: 0,
    x: 0,
    y: 0,
    width: 816,
    windowWidth: 816,
    html2canvas: {
      ignoreElements: (element: HTMLElement) => {
        return element.classList.contains("exclude-from-pdf")
      },
      backgroundColor: "#ffffff",
    },
    callback: async (doc) => {
      doc.save(filename, { returnPromise: true }).then(completion)
    },
  })
}

export default PdfSharedPage
