import { styled } from "@mui/material/styles"
import { InputBase } from "@mui/material"
import { Search } from "@mui/icons-material"

const StyledSearch = styled(Search)(({ theme }) => ({
  margin: theme.spacing(1),
  fontSize: 22,
}))

const SearchInputBase = styled(InputBase)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  width: "100%",
  "& .MuiInputBase-input": {
    transition: theme.transitions.create("width"),
  },
}))

const RootDiv = styled("div")(({ theme }) => ({
  margin: "0px",
  backgroundColor: theme.palette.grey[200],
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  width: "100%",
  maxWidth: "280px",
  [theme.breakpoints.down("sm")]: { maxWidth: "100%" },
}))

const SearchBar = ({
  placeholder,
  onChange,
  autoFocus,
}: {
  placeholder: string | null | undefined
  onChange: (text: string) => void
  autoFocus?: boolean
}) => {
  return (
    <RootDiv>
      <SearchInputBase
        type="search"
        placeholder={placeholder || ""}
        onChange={(event) => {
          const text = event.target.value as string
          onChange(text)
        }}
        autoFocus={autoFocus}
      />
      <StyledSearch />
    </RootDiv>
  )
}

export default SearchBar
