import { Box, Chip, Divider, ListItemButton, styled } from "@mui/material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import TextWithFallbackView from "components/views/generic/TextWithFallbackView"
import { Link } from "react-router-dom"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1),
}))

const LeftInnerColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: theme.spacing(0.5),
}))

const RightInnerColumn = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  textAlign: "right",
  justifyContent: "space-between",
  gap: theme.spacing(1),
}))

const StatusBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "end",
  alignItems: "center",
  gap: theme.spacing(0.5),
}))

const DocumentTypeTextWithFallbackView = styled(TextWithFallbackView)({
  fontWeight: "bold",
})

const DocumentsListItem = ({
  teamKey,
  item,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.documents.data.view.list.DocumentsListItemViewData
}) => {
  const documentPath = paths.document(teamKey, item.documentAnswerKey)
  return (
    <>
      <ListItemButton component={Link} key={item.viewKey} to={documentPath}>
        <StyledBox>
          <LeftInnerColumn>
            <DocumentTypeTextWithFallbackView data={item.documentName} />
            <TextWithFallbackView data={item.project} />
            <TextWithFallbackView data={item.client} />
          </LeftInnerColumn>
          <RightInnerColumn>
            <TextWithFallbackView data={item.createdAtFormatted} />
            <TextWithFallbackView data={item.total} />
            <StatusBox>
              {item.paymentStatus && <Chip label={item.paymentStatus} />}
              <Chip label={item.sendStatus} />
            </StatusBox>
          </RightInnerColumn>
        </StyledBox>
      </ListItemButton>
      <Divider />
    </>
  )
}

export default DocumentsListItem
