import React, { HTMLAttributes, useState } from "react"
import { SxProps, Theme } from "@mui/system"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import Autocomplete, {
  AutocompleteRenderOptionState,
} from "@mui/material/Autocomplete"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { AutocompleteChangeReason } from "@mui/material/useAutocomplete"

import { fetchPlaceDetails, fetchPlacePredictions } from "model/google/Maps"

const StyledLocationOnIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(2),
}))

const AddressTextField = ({
  placeholder,
  defaultValue,
  onChange,
  sx,
  disabled,
}: {
  placeholder: string | null | undefined
  defaultValue: string | null
  onChange: (address: string, lat: number | null, lng: number | null) => void
  sx?: SxProps<Theme>
  disabled?: boolean
}) => {
  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([])
  return (
    <>
      <Autocomplete
        freeSolo
        getOptionLabel={(
          option: string | google.maps.places.AutocompletePrediction
        ) => (typeof option === "string" ? option : option.description)}
        options={options}
        autoComplete
        includeInputInList
        value={defaultValue}
        disabled={disabled}
        onChange={(
          event: React.ChangeEvent<{}>,
          newValue: string | google.maps.places.AutocompletePrediction | null,
          reason: AutocompleteChangeReason
        ) => {
          if (reason === "selectOption") {
            const placeId = (
              newValue as google.maps.places.AutocompletePrediction
            ).place_id
            if (placeId !== null) {
              fetchPlaceDetails(
                placeId,
                (result: google.maps.places.PlaceResult) => {
                  onChange(
                    result.formatted_address || "",
                    result.geometry?.location.lat() || null,
                    result.geometry?.location.lng() || null
                  )
                }
              )
            }
          }
        }}
        onInputChange={(
          event: React.ChangeEvent<{}>,
          newInputValue: string
        ) => {
          if (newInputValue !== defaultValue) {
            onChange(newInputValue, null, null)
            fetchPlacePredictions(newInputValue, (results) =>
              setOptions(results)
            )
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={placeholder}
            fullWidth
            sx={sx}
          />
        )}
        renderOption={(
          props: HTMLAttributes<HTMLLIElement>,
          option: google.maps.places.AutocompletePrediction,
          state: AutocompleteRenderOptionState
        ) => {
          return (
            <AutoCompleteOption props={props} option={option} key={option.id} />
          )
        }}
      />
    </>
  )
}

const AutoCompleteOption = ({
  props,
  option,
}: {
  props: HTMLAttributes<HTMLLIElement>
  option: google.maps.places.AutocompletePrediction
}) => {
  return (
    <li {...props}>
      <Grid container alignItems="center">
        <Grid item>
          <StyledLocationOnIcon />
        </Grid>
        <Grid item xs>
          <span style={{ fontWeight: 400 }}>
            {option.structured_formatting.main_text}
          </span>
          <Typography variant="body2" color="textSecondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  )
}

export default AddressTextField
