import { useRef, useState } from "react"
import { styled } from "@mui/material/styles"
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material"

import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import LoadingModal from "components/views/generic/LoadingModal"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import UserAvatar from "components/views/users/UserAvatar"
import ImageInput from "components/views/generic/ImageInput"
import UserProfileActionList from "./UserProfileActionList"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"
import EditIcon from "@mui/icons-material/Edit"
import PhoneNumberChangeAddDialog from "components/dialogs/PhoneNumberChangeAddDialog"
import DropdownView from "components/views/generic/DropdownView"
import UserDeviceListItemView from "./UserAgentDeviceListItemView"

const AvatarContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}))

const AvatarButtonContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
}))

const UserProfileEditView = ({
  teamKey,
  userKey,
  editInput,
  previewInput,
  editViewData,
  previewViewData,
  navigateSafeState,
}: {
  teamKey: string
  userKey: string
  editViewData: shared.com.probuildsoftware.probuild.library.users.data.view.UserProfileEditViewData | null
  previewViewData: shared.com.probuildsoftware.probuild.library.users.data.view.UserProfilePreviewViewData | null
  editInput: shared.com.probuildsoftware.probuild.library.users.UserProfileEditInput | null
  previewInput: shared.com.probuildsoftware.probuild.library.users.UserProfilePreviewInput | null
  navigateSafeState: NavigateSafeState
}) => {
  const avatarImageInputRef = useRef<HTMLInputElement>(null)
  const [isChangingPhoneNumber, setIsChangingPhoneNumber] =
    useState<boolean>(false)
  usePromptSafe({
    when: editViewData?.shouldConfirmDiscard === true,
    message: editViewData?.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!editInput || !previewInput || !editViewData) return null
  return (
    <div>
      <AvatarContainerDiv>
        <UserAvatar size={150} viewData={editViewData.avatar} />
        <ImageInput
          inputRef={avatarImageInputRef}
          hidden={true}
          onFileChanged={(file) => {
            editInput.changeAvatarFile(file)
          }}
        />
      </AvatarContainerDiv>
      <AvatarButtonContainerDiv>
        {editViewData.removeAvatarButtonText && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => editInput.removeAvatarFile()}
          >
            {editViewData.removeAvatarButtonText}
          </Button>
        )}
        {editViewData.changeAvatarButtonText && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              avatarImageInputRef.current?.click()
            }}
          >
            {editViewData.changeAvatarButtonText}
          </Button>
        )}
      </AvatarButtonContainerDiv>
      <UserProfileActionList
        teamKey={teamKey}
        userKey={userKey}
        viewData={previewViewData}
      />
      <LabeledCard text={""}>
        <TextField
          label={editViewData.phoneNumber.label}
          defaultValue={editViewData.phoneNumber.value}
          variant="standard"
          disabled={true}
          InputProps={{
            endAdornment: (
              <>
                {editViewData?.changePhoneNumberButtonText && (
                  <InputAdornment position="end">
                    <Tooltip
                      placement="top"
                      arrow
                      title={editViewData.changePhoneNumberButtonText}
                    >
                      <IconButton
                        aria-label={editViewData.changePhoneNumberButtonText}
                        onClick={() => setIsChangingPhoneNumber(true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
        <ViewDataTextField
          viewData={editViewData.firstName}
          onChange={(text) => editInput?.changeFirstName(text)}
        />
        <ViewDataTextField
          viewData={editViewData.lastName}
          onChange={(text) => editInput?.changeLastName(text)}
        />
        <DropdownView
          viewData={editViewData.roleDropdown}
          onChange={(roleKey) => {
            editInput?.roleChangeInitiated(roleKey)
          }}
        />
      </LabeledCard>
      {editViewData.devices && (
        <LabeledCard text={"Device History"}>
          <UserDeviceListItemView item={editViewData} />
        </LabeledCard>
      )}
      <LoadingModal
        open={editViewData.isSaveInProgress || editViewData.isDeleteInProgress}
      />
      {userKey && (
        <PhoneNumberChangeAddDialog
          teamKey={teamKey}
          userKey={userKey}
          isOpen={isChangingPhoneNumber}
          onCloseDialog={() => setIsChangingPhoneNumber(false)}
          navigateSafeState={navigateSafeState}
        />
      )}
      <ConfirmationDialog
        open={editViewData?.changeRoleConfirmationDialog != null}
        onConfirm={() => editInput?.roleChangeConfirmed()}
        onCancel={() => editInput?.roleChangeCancelled()}
        dialogViewData={editViewData?.changeRoleConfirmationDialog}
      />
      <ConfirmationDialog
        open={editViewData?.deleteConfirmationDialog != null}
        onConfirm={() => editInput?.deleteConfirmed()}
        onCancel={() => editInput?.deleteCancelled()}
        dialogViewData={editViewData?.deleteConfirmationDialog}
      />
      <ConfirmationDialog
        open={editViewData?.deleteDoubleConfirmationDialog != null}
        onConfirm={() => editInput?.deleteDoubleConfirmed()}
        onCancel={() => editInput?.deleteCancelled()}
        dialogViewData={editViewData?.deleteDoubleConfirmationDialog}
      />
    </div>
  )
}

export default UserProfileEditView
