import * as shared from "probuild-shared"

import { useEffect, useState } from "react"

import useAppUser from "model/utils/useAppUser"
import useDependencyProvider from "model/utils/useDependencyProvider"

function useFirebaseReportData({
  teamKey,
  documentKey,
}: {
  teamKey: string
  documentKey: string | undefined
}): shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null {
  const [reportData, setReportData] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData | null>(
      null
    )
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  useEffect(() => {
    if (dependencyProvider && appUser) {
      const reportDataTransformer =
        new shared.com.probuildsoftware.probuild.library.documents.ReportDataTransformer()
      const presenter =
        new shared.com.probuildsoftware.probuild.library.documents.DocumentPresenter(
          dependencyProvider,
          null,
          appUser,
          {
            projectKey: null,
            documentDefinitionKey: null,
            documentKey,
            restoreStateJson: null,
            isCopyAndConvertSupported: false,
            isPrintSupported: false,
            isSharePdfSupported: false,
          }
        )
      return presenter.observeReportViewData({
        onDocumentReportDataChanged(
          reportData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentReportViewData
        ) {
          const updatedReportData = reportDataTransformer.transformPaths(
            reportData,
            (path) =>
              `/api/storage/${path}?resize=600x600&jpegQuality=80&rotate=true&fit=inside`
          )
          setReportData(updatedReportData)
        },
      })
    }
  }, [appUser, documentKey, dependencyProvider])
  return reportData
}

export default useFirebaseReportData
