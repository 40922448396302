import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import * as shared from "probuild-shared"
import LoadingModal from "components/views/generic/LoadingModal"
import ViewDataTextField from "components/views/generic/ViewDataTextField"

const DocumentReportSignDialog = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentSharedViewData | null
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentSharedInput | null
}) => {
  const nameViewData = viewData?.signingDialog?.name
  const open = viewData?.signingDialog != null
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Dialog
      maxWidth={isMobileScreen ? "xl" : undefined}
      open={open}
      onClose={() => input?.signatureDialogCancel()}
      PaperProps={{
        sx: {
          alignSelf: isMobileScreen ? "flex-start" : undefined,
        },
      }}
    >
      <DialogTitle>{viewData?.signingDialog?.title}</DialogTitle>
      <DialogContent>
        {nameViewData && (
          <ViewDataTextField
            fullWidth
            viewData={nameViewData}
            onChange={(text) => {
              input?.signatureDialogChangeName(text)
            }}
          />
        )}
        {viewData?.signingDialog?.errorAlertText && (
          <Alert icon={false} severity="error">
            {viewData?.signingDialog?.errorAlertText}
          </Alert>
        )}
        <Typography sx={{ pt: isMobileScreen ? 4 : 2 }}>
          {viewData?.signingDialog?.disclaimerText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => input?.signatureDialogCancel()}
        >
          {viewData?.signingDialog?.cancelButtonTitle}
        </Button>
        <Button
          variant="contained"
          color="success"
          size="large"
          onClick={async () => {
            input?.signatureDialogComplete()
          }}
        >
          {viewData?.signingDialog?.addSignatureButtonTitle}
        </Button>
      </DialogActions>
      <LoadingModal open={viewData?.isLoadingIndicatorVisible === true} />
    </Dialog>
  )
}

export default DocumentReportSignDialog
