import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

const useDocumentShared = ({
  teamKey,
  sharedId,
  disableOpenTracking,
}: {
  teamKey?: string
  sharedId?: string
  disableOpenTracking: boolean
}): [
  shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentSharedViewData | null,
  shared.com.probuildsoftware.probuild.library.documents.DocumentSharedInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && teamKey && sharedId) {
      console.log(
        `Creating document shared presenter teamKey = ${teamKey} sharedId = ${sharedId} disableOpenTracking = ${disableOpenTracking}`
      )
      return new shared.com.probuildsoftware.probuild.library.documents.DocumentSharedPresenter(
        dependencyProvider,
        {
          teamKey,
          sharedId,
          disableOpenTracking,
        }
      )
    }
  }, [teamKey, sharedId, dependencyProvider, disableOpenTracking])
  return usePresenter(presenter)
}

export default useDocumentShared
