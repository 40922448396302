import { Divider, ListItem, ListItemText, Typography } from "@mui/material"

import * as shared from "probuild-shared"

const UserDeviceListItemView = ({
  item,
}: {
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserProfileEditViewData | null
}) => {
  const userDeviceListItems = item?.devices?.asJsReadonlyArrayView() || []

  return (
    <>
      {userDeviceListItems?.map((listItem, index) => {
        return (
          <>
            <ListItem
              style={{
                opacity: listItem.deletedAtDateFormatted ? 0.5 : 1,
                padding: 0,
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                primary={
                  <>
                    <Typography>{`${listItem.os} ${
                      listItem.osVersion ?? ""
                    }`}</Typography>
                    <Typography>{listItem.appVersion}</Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography>
                      {listItem.model || listItem.userAgent}
                    </Typography>
                    {listItem.deletedAtDateFormatted && (
                      <Typography>
                        Deleted on {listItem.deletedAtDateFormatted}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
            {index < userDeviceListItems.length - 1 && <Divider />}
          </>
        )
      })}
    </>
  )
}

export default UserDeviceListItemView
